
import { of as observableOf, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserService } from './user.service';

@Injectable()
export class AuthGuardService  {

  constructor(private userService: UserService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.userService.setInitRoute(state.url);
    return this.userService.getUser().pipe(
      map((res: any) => {
        return res.data ? true : false;
      }),
      catchError((error: any) => {
        this.router.navigate(['login']);
        return observableOf(false);
      }));
  }

}