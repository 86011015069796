import {enableProdMode, LOCALE_ID, importProvidersFrom} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';


import {environment} from './environments/environment';
import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from './app/app.component';
import {AppRoutingModule} from './app/app-routing.module';
import {provideAnimations} from '@angular/platform-browser/animations';
import {EventService} from './app/shared/services/event.service';
import {UserService} from './app/shared/services/user.service';
import {AuthService} from './app/shared/services/auth.service';
import {AuthGuardService} from './app/shared/services/auth-guard.service';
import {HttpApiService} from './app/shared/services/http-api.service';
import {InterceptorService} from './app/shared/services/interceptor.service';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient
} from '@angular/common/http';
import {provideStore} from '@ngrx/store';
import {provideEffects} from '@ngrx/effects';
import {reducers} from "./app/store/reducers";
import {CustomersEffects} from "./app/store/customers/customers.effects";
import {CustomersService} from "./app/shared/services/customers.service";

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(AppRoutingModule),
    {
      provide: LOCALE_ID,
      useValue: 'fr'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    HttpApiService,
    AuthGuardService,
    AuthService,
    UserService,
    EventService,
    CustomersService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideStore(reducers),
    provideEffects([CustomersEffects])
  ]
})
  .catch(err => console.error(err));
