import { createAction, props } from '@ngrx/store';
import { Customer } from '../../models/customer.model';
import {FilterCustomer} from "../../models/filter-customer.model";

export const loadCustomers = createAction('[Customers] Load Customers');

export const updateFilter = createAction(
  '[Customers] Update Filter',
  props<{ filter: FilterCustomer }>()
);

export const loadCustomersSuccess = createAction(
  '[Customers] Load Customers Success',
  props<{ customers: Customer[] }>()
);
export const loadCustomersFailure = createAction(
  '[Customers] Load Customers Failure',
  props<{ error: string }>()
);
