import { NgModule } from '@angular/core';
import { Routes, RouterModule , PreloadAllModules} from '@angular/router';
import { AuthGuardService } from '../app/shared/services/auth-guard.service';
import { authGuard } from './shared/auth.guard';

const routes: Routes = [
  { path: 'home', canActivate: [authGuard], loadChildren: () => import('src/app/home/home.module').then(m => m.HomeModule) },
  { path: '', loadChildren: () => import('src/app/public/public.module').then(m => m.PublicModule) },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    preloadingStrategy: PreloadAllModules
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
