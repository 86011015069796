import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { loadCustomers, loadCustomersSuccess, loadCustomersFailure, updateFilter } from './customers.actions';
import { selectFilter } from './customers.selectors';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import {CustomersService} from "../../shared/services/customers.service";
import {Filter, Search} from "../../shared/model/search";
import {FilterCustomer} from "../../models/filter-customer.model";

@Injectable()
export class CustomersEffects {
  constructor(private actions$: Actions, private store: Store, private customerService: CustomersService) {}

  // Charger les clients lorsqu'on met à jour le filtre
  loadCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomers, updateFilter),
      withLatestFrom(this.store.pipe(select(selectFilter))),
      switchMap(([_, filter]) =>
        this.customerService.get(this.buildSearch(filter)).pipe(
          map((customers) => loadCustomersSuccess({ customers: customers.data })),
          catchError((error) => of(loadCustomersFailure({ error: error.message })))
        )
      )
    )
  );

  buildSearch(filter: FilterCustomer): Search {
    let search: Search = new Search();
    if (filter.search) {
      search.filter.push(new Filter("name", filter.search));
    }
    if (filter.from) {
      let from = new Date(filter.from.year, filter.from.month - 1, filter.from.day, 0, 0, 0, 0);
      search.filter.push(new Filter("since", from.getTime()));
    }
    if (filter.until) {
      let until = new Date(filter.until.year, filter.until.month - 1, filter.until.day, 0, 0, 0, 0);
      search.filter.push(new Filter("until", until.getTime()));
    }
    if (filter.type) {
      if (filter.type == 'ALL') {
        search.filter.push(new Filter("type", "CUSTOMER"));
        search.filter.push(new Filter("type", "PROSPECT"));
      } else {
        search.filter.push(new Filter("type", filter.type));
      }
    }
    return search;
  }
}
