
import {timeout} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class HttpApiService {

  /**
   * AUTH_TOKEN   : It is a private key, and must be set in http header only when login or signup
   * ACCESS_TOKEN : It is a communication API key, and save it in local storage
   */
  private API_URL: string = environment.apiurl || '';

  constructor(private http: HttpClient,  private router: Router) { }

  getRequestOptions(options?: any) : any {
    let httpHeaders = {
   //   'Content-Type' : 'application/json; charset=utf-8'
    }
    
    if (options && options.headers) {
      if (options.headers['contentType']) {
        httpHeaders['Content-Type'] = options.headers['contentType'];
      }

      if (options.headers['token']) {
        httpHeaders['Authorization'] = options.headers['token'];
      }
    }

    return { headers: new HttpHeaders(httpHeaders) };
  }

  get(url: string, options?: any): Observable<any> {
    return this.http.get(this.API_URL + url, this.getRequestOptions(options)).pipe(timeout(30000));
  }
 
  post(url: string, body?: any, options?: any): Observable<any> {
		return this.http.post(this.API_URL + url, body, this.getRequestOptions(options)).pipe(timeout(30000));
  }

  put(url: string, body?: any, options?: any): Observable<any> {
    return this.http.put(this.API_URL + url, body, this.getRequestOptions(options)).pipe(timeout(30000));
  }

  delete(url: string, options?: any): Observable<any> {
    return this.http.delete(this.API_URL + url, this.getRequestOptions(options)).pipe(timeout(30000));
  }

}
