import { Injectable, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpApiService } from './http-api.service';

@Injectable()
export class EventService {

    /* in order to emit data change */
    constructor(private httpApi: HttpApiService) { }

    public onMainEventUpdated: EventEmitter<any> = new EventEmitter<any>();

    eventData: any;

    getEvents(): Observable<any> {
        return this.httpApi.get(`/event`);
    }

    putEvent(event: any): Observable<any> {
        return this.httpApi.put(`/event`, event);
    }

    postEvent(eventId: any, event: any) : Observable<any> {
        return this.httpApi.post(`/event/${eventId}`, event);
    }

    getMainEvent(): Observable<any> {
        if (this.eventData) {
            return of(this.eventData)
        }
        return this.httpApi.get(`/event/main`).pipe(
            tap(value => {
                this.eventData = value;
            })
        );
    }

    updateMainEvent() {
        this.onMainEventUpdated.emit();
    }

    postMainEvent(eventId: any): any {
        delete this.eventData;
        return this.httpApi.post(`/event/${eventId}/main`);
    }

    getCollaborators(eventId: any): Observable<any> {
        return this.httpApi.get(`/event/${eventId}/collaborators`);
    }

    putCollaborator(eventId: any, data: any) {
        return this.httpApi.put(`/event/${eventId}/collaborators`, data);
    }

    getSubEvents(eventId: any): any {
        return this.httpApi.get(`/event/${eventId}/subevent`);
    }

    clearLocalEvent(): void {
        this.eventData = null;
    }
}
