import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpApiService } from './http-api.service';
import { Search } from '../model/search';

@Injectable()
export class CustomersService {

  /* in order to emit data change */
  constructor(private httpApi : HttpApiService) { }

  get(search: Search): Observable<any> {
    return this.httpApi.get(`/pro/customer` + search.buildRequest());
  }

  getById(id: any): Observable<any> {
    return this.httpApi.get(`/pro/customer/` + id);
  }

  put(body:any): Observable<any> {
    return this.httpApi.put(`/pro/customer`, body);
  }

  post(customerId: any, body:any): Observable<any> {
    return this.httpApi.post(`/pro/customer/${customerId}`, body);
  }

  delete(customerId: any): Observable<any> {
    return this.httpApi.delete(`/pro/customer/${customerId}`);
  }

  getNotes(customerId: any): Observable<any> {
    return this.httpApi.get(`/pro/customer/${customerId}/note`);
  }

  putNote(customerId: any, body:any): Observable<any> {
    return this.httpApi.put(`/pro/customer/${customerId}/note`, body);
  }

  postNote(customerId: any, noteId: any, body:any): Observable<any> {
    return this.httpApi.post(`/pro/customer/${customerId}/note/${noteId}`, body);
  }

  deleteNote(customerId: any, noteId: any): Observable<any> {
    return this.httpApi.delete(`/pro/customer/${customerId}/note/${noteId}`);
  }
}
