import {FilterCustomer} from "../../models/filter-customer.model";
import {Customer} from "../../models/customer.model";

export interface CustomersState {
  filter: FilterCustomer;
  customers: Customer[];
  loading: boolean;
  error: string | null;
}

export const initialCustomersState: CustomersState = {
  filter:  {
    name: "",
    search: "",
    type: "ALL",
    from: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() },
    until: null,
  },
  customers: [],
  loading: true,
  error: null
};
