import { createReducer, on } from '@ngrx/store';
import {initialCustomersState} from "./customers.state";
import {
  loadCustomers,
  loadCustomersFailure,
  loadCustomersSuccess, updateFilter
} from "./customers.actions";

export const customersReducer = createReducer(
  initialCustomersState,

  // Chargement des clients
  on(loadCustomers, (state) => ({
    ...state,
    loading: true,
    error: null
  })),

  // Mise à jour des clients après récupération
  on(loadCustomersSuccess, (state, { customers }) => ({
    ...state,
    customers,
    loading: false
  })),

  // Gestion des erreurs
  on(loadCustomersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),

  // Mise à jour du filtre
  on(updateFilter, (state, { filter }) => ({
    ...state,
    loading: true,
    filter
  }))
);
