export const environment = {
  production: true,
  apiurl : "https://api.easyevent.fr/api",
  eventId : 1,
  stripe: {
    key: "",
    success_url: "",
    cancel_url: ""
  }
};
