
import { of as observableOf, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';


import { HttpApiService } from './http-api.service';
import { AuthService } from './auth.service';

@Injectable()
export class UserService {

  USER_KEY = 'current_user';
  USER_NAME_KEY = 'current_username';
  INIT_ROUTE_KEY = 'init_route';
  userData: any;

  /* in order to emit data change */
  constructor(private httpApi: HttpApiService, private authService: AuthService) { }

  getUser(): Observable<any> {
    return new Observable((observer) => {
      if (this.userData) {
        observer.next(this.userData);
        observer.complete();
      } else {
        this.httpApi.get('/user/me').subscribe(res => {
          this.userData = res;
          localStorage.setItem(this.USER_KEY, this.userData);
          observer.next(this.userData);
          observer.complete();
        });
      }
    });
  }

  updateUser(profile: any): Observable<any> {
    return this.httpApi.post('/user/me', profile);
  }

  setUsername(email: string): void {
    if (email) {
      localStorage.setItem(this.USER_NAME_KEY, email);
    }
  }
  getUsername(): string {
    return localStorage.getItem(this.USER_NAME_KEY) || '';
  }

  clearUserName(): void {
    localStorage.removeItem(this.USER_NAME_KEY);
  }

  isLoggedin(): boolean {
    return this.authService.getAccessToken() !== null;
  }

  register(user: any): Observable<any> {
    return this.httpApi.put('/user/register', user, {
      headers: {
        token: this.authService.AUTH_TOKEN
      }
    });
  }

  activation(key: string): any {
    return this.httpApi.get(`/api/activate?key=${key}`, {
      headers: {
        token: this.authService.AUTH_TOKEN
      }
    });
  }

  changePassword(oldP: string, newP: string) {
    var newPass = {
      oldPassword: oldP,
      newPassword: newP
    }
    return this.httpApi.post('/account/change_password', newPass);
  }

  reset(email: string): any {
    return this.httpApi.post('/api/account/reset_password/init', email);
  }

  resetPassword(keyPass: any): any {
    return this.httpApi.post('/api/account/reset_password/finish', keyPass);
  }

  clearLocalUser(): void {
    this.userData = null;
    localStorage.removeItem(this.USER_KEY);
  }

  /*
   * We registered the route asked by the user in localstorage. If we get one, we redirect him in this route else on the route by default. 
   */
  getRedirectInitRoute(): string {
    if(localStorage.getItem(this.INIT_ROUTE_KEY)) {
      return localStorage.getItem(this.INIT_ROUTE_KEY);
    } else {
      return '/home'; // default route to redirect
    }
  }

  setInitRoute(route: string) {
    localStorage.setItem(this.INIT_ROUTE_KEY, route);
  }

  /* Login */
  login(email: any, password: any): Observable<any> {
    let body = {
      username: email,
      password: password
    }
    return this.httpApi.post('/account/authentication', body, {
      headers: {
        contentType: 'application/json',
        token: this.authService.AUTH_TOKEN
      }
    });
  }

  /* Logout
   * clea all local storage
   */
  logout(): any {
    this.clearLocalUser();
    this.authService.apiLogout();
    //this.authService.logout();
  }
}
