import { Injectable, inject } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from './services/user.service';

@Injectable({
  providedIn: 'root'
})
class PermissionsService {

  constructor(private userService: UserService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.userService.setInitRoute(state.url);
    return this.userService.getUser().pipe(
      map((res: any) => {
        return res.data ? true : false;
      }),
      catchError((error: any) => {
        this.router.navigate(['login']);
        return observableOf(false);
      }));
  }
}

export const authGuard: CanActivateFn = (route, state) => {
  return inject(PermissionsService).canActivate(route, state);
};
