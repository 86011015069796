export class Search {

    filter: Filter[] = [];

    buildRequest() {
        let result = "";

        if (this.filter.length == 0)
            return result;

        result = "?";

        for (let i = 0; i < this.filter.length; i++) {
            if (i != 0)
                result = result + "&";
            result = result + this.filter[i].name + "=" + this.filter[i].value;
        }

        return result;
    }
}

export class Filter {
    name: String;
    value: any;

    constructor(name: string, value: any) {
        this.name = name;
        this.value = value;
    }
}