
import { throwError as observableThrowError, BehaviorSubject, Observable, throwError } from 'rxjs';
import { switchMap, catchError, finalize, filter, take } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpEvent, HttpRequest, HttpErrorResponse } from '@angular/common/http'
import { AuthService } from './auth.service'

@Injectable()
export class InterceptorService implements HttpInterceptor {

    authService: AuthService;
    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private injector: Injector) {
        this.authService = this.injector.get(AuthService);
    }

    addToken(req: HttpRequest<any>): HttpRequest<any> {
        if (req.headers.get('Authorization') && req.headers.get('Authorization') != "") {
            return req;
        } else {
            let token = this.authService.getAccessToken();
            return req.clone({ setHeaders: { Authorization: token } })
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        return next.handle(this.addToken(req))
            .pipe(
                catchError((error: any) => {
                    if (error instanceof HttpErrorResponse) {
                        switch ((<HttpErrorResponse>error).status) {
                            case 401:
                                if (error.url && error.url.indexOf('account/refreshtoken') !== -1) {
                                    return throwError(() => new Error(error.message));
                                }
                                return this.handle401Error(req, next);
                            case 0:
                                this.authService.logout();
                                return throwError(() => new Error(error.message));
                            default:
                                return throwError(() => new Error(error.message));
                        }
                    } else {
                        this.authService.logout();
                        return throwError(() => new Error(error.message));
                    }
                }));
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap((res: any) => {

                    if (res && res['access_token'] && res['refresh_token']) {
                        let accessToken = res['token_type'] + ' ' + res['access_token'];
                        let refreshToken = res['refresh_token'];
                        this.authService.setAccessToken(accessToken);
                        this.authService.setRefreshToken(refreshToken);

                        if (accessToken) {
                            this.tokenSubject.next(accessToken);
                            return next.handle(this.addToken(req));
                        }
                    }
                    // If we don't get a new token, we are in trouble so logout.
                    this.authService.logout();
                    return observableThrowError('');
                }),
                catchError((error: any) => {
                    // If there is an exception calling 'refreshToken', bad news so logout.
                    this.authService.logout();
                    return observableThrowError('');
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }));
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    if (token) {
                        return next.handle(this.addToken(req));
                    }
                }));
        }
    }

}