import {createSelector, createFeatureSelector} from '@ngrx/store';
import {CustomersState} from "./customers.state";

// Selector for the feature state
export const selectCustomersState = createFeatureSelector<CustomersState>('customers');
export const selectCustomers = createSelector(
  selectCustomersState,
  (state) => state.customers
);

export const selectFilter = createSelector(
  selectCustomersState,
  (state) => state.filter
);

export const selectCustomersLoading = createSelector(
  selectCustomersState,
  (state) => state.loading
);

export const selectCustomersError = createSelector(
  selectCustomersState,
  (state) => state.error
);
