
import { throwError as observableThrowError, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { HttpApiService } from './http-api.service';

@Injectable()
export class AuthService {

    private API_URL: string = environment.apiurl || '';
    public AUTH_TOKEN = 'Basic ZXZlbnRfcGxhbm5lcl93ZWJhcHA6ZXZlbnRfc2VjcmV0';
    public ACCESS_TOKEN_KEY = 'access_token';
    public REFRESH_TOKEN_KEY = 'refresh_token';

    constructor(
        private _http: HttpClient,
        private router: Router,
        private httpApi: HttpApiService) { }

    setAccessToken(token: string): void {
        localStorage.setItem(this.ACCESS_TOKEN_KEY, token);
    }

    getAccessToken(): any {
        return (localStorage.getItem(this.ACCESS_TOKEN_KEY) || this.AUTH_TOKEN);
    }

    setRefreshToken(token: string): void {
        localStorage.setItem(this.REFRESH_TOKEN_KEY, token);
    }

    getRefreshToken(): any {
        return localStorage.getItem(this.REFRESH_TOKEN_KEY);
    }

    refreshToken(): Observable<any> {
        let currentToken = this.getRefreshToken();

        if (!currentToken) {
            this.logout();
            return throwError(() => "");
        }

        let url = `/account/refreshtoken?token=${currentToken}`;
        let httpHeaders = {
            'Content-Type': 'application/json',
            'Authorization': this.AUTH_TOKEN
        };
        this.setAccessToken(this.AUTH_TOKEN);
        return this._http.post(this.API_URL + url, null, { headers: new HttpHeaders(httpHeaders) });
    }

    apiLogout(): void {
        let accessToken = this.getAccessToken();
        if (accessToken !== this.AUTH_TOKEN) {
            this.httpApi.get(`/account/logout`).subscribe((res: any) => {
                this.setAccessToken(this.AUTH_TOKEN);
                localStorage.removeItem(this.REFRESH_TOKEN_KEY);
            });
            this.logout();
        }
    }

    logout(): any {
        this.router.navigate(['login']);
    }
}
